import ContactForm from "./ContactForm";


function CreateContact() {

  return (
    <ContactForm name="" address="" email="" phone="" category="" />
  );
}

export default CreateContact;
